.App {
    padding: 16px;
}

.header {
    font: 1.4em "Arial";
    padding: 16px;
}

.wizardrow {
    padding: 12px;
}

.navigationrow {
    padding: 12px;
}

.filterrow {
    padding: 12px;
}

.instructions {
    padding: 8px;
}

.instructions-title {
    padding: 8px;
    font: 1.4em "Arial";
}
